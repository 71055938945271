import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
// import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { PDFGenerator } from '@awesome-cordova-plugins/pdf-generator/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

// import { File } from '@awesome-cordova-plugins/file/ngx';
// import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
// import { FileOpener } from '@ionic-native/file-opener/ngx';
// import { File } from '@ionic-native/file/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import ApkUpdater from 'cordova-plugin-apkupdater';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, HttpClientModule, IonicModule.forRoot(), AppRoutingModule],
  providers: [PDFGenerator, ScreenOrientation,
    // File,
    // FileOpener,
    // FileOpener,
    // File, 
    File,
    FileOpener,
    AppUpdate,
    ApkUpdater,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
