import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import { LoadingService } from './services/LoadingService';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public menupages = [{ title: 'SALES BILL', url: 'bill', icon: 'list-outline' },
  { title: 'Outstandings', url: 'agents', icon: 'receipt-outline' },
  { title: 'Ledgers', url: 'party', icon: 'bar-chart-outline' },
  { title: 'Order Pending', url: 'pendingoffers', icon: 'dice-outline' }];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  UserObj: any;
  HomePageData: any;
  constructor(private platform: Platform,
    private router: Router,
    private menuCtrl: MenuController,
    private _location: Location,
    private appUpdate: AppUpdate,
    public loading: LoadingService,
    public alertController: AlertController,) {
    this.initializeApp();
    this.UserObj = JSON.parse(localStorage.getItem("UserObj"));
    console.log('14..');

    // this.loading.present();

    //Dynamic Menu  
    // this.getMenus();
    // // this.loading.dismiss();
    // console.log('sss', this.menupages);

  }


  initializeApp() {
    this.platform.ready().then(() => {
    });


    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/home')) {

        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {

        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();

      }

    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!');
      this.alertController.getTop().then(r => {
        if (r) {
          navigator['app'].exitApp();
        }
      }).catch(e => {
        console.log(e);
      })
    });
  }

  async ionViewDidEnter() {

    // this.loading.present();
    // this.HomePageData = JSON.parse(localStorage.getItem("HomePageData"));
    // //Dynamic Menu  
    // await this.getMenus();
    // this.loading.dismiss();
    // console.log('sss', this.menupages);
  }

  async getMenus() {

    if (this.HomePageData) {
      this.menupages = [];

      if (this.UserObj != null && this.UserObj.AccountType == 'Admin') {

        if (this.HomePageData != null && this.HomePageData.Is_SalesBill == true) {
          this.menupages.push({ title: 'SALES BILL', url: 'bill', icon: 'list-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_Outstanding == true) {
          this.menupages.push({ title: 'Outstandings', url: 'agents', icon: 'receipt-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_Ledger == true) {
          this.menupages.push({ title: 'Ledgers', url: 'party', icon: 'bar-chart-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_ProcessStock == true) {
          this.menupages.push({ title: 'Process Stock', url: 'graystock', icon: 'pulse-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_GreyStock == true) {
          this.menupages.push({ title: 'Grey Stock', url: 'factorygreystock', icon: 'prism-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_FactoryYarnStock == true) {
          this.menupages.push({ title: 'Factory Yarn FA', url: 'factorystock', icon: 'swap-vertical-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_Grading == true) {
          this.menupages.push({ title: 'Grading Stock', url: 'gradingstock', icon: 'document-text-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_OrderPending == true) {
          this.menupages.push({ title: 'Order Pending', url: 'pendingoffers', icon: 'dice-outline' });
        }
      }

      if (this.UserObj != null && this.UserObj.AccountType != 'Admin') {

        if (this.HomePageData != null && this.HomePageData.Is_SalesBill == true) {
          this.menupages.push({ title: 'SALES BILL', url: 'bill', icon: 'list-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_Outstanding == true) {
          this.menupages.push({ title: 'Outstandings', url: 'agents', icon: 'receipt-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_Ledger == true) {
          this.menupages.push({ title: 'Ledgers', url: 'party', icon: 'bar-chart-outline' });
        }

        if (this.HomePageData != null && this.HomePageData.Is_OrderPending == true) {
          this.menupages.push({ title: 'Order Pending', url: 'pendingoffers', icon: 'dice-outline' });
        }
      }
    }
  }


  async updateMenuState(isval) {
    console.log('isval', isval);
    await this.getMenus();

  }


  OpenPageLink(url) {
    localStorage.setItem("ViaAgent", JSON.stringify(false));
    this.router.navigate(['/' + url]);
  }

  GetAgents() {
    this.router.navigate(['/agents']);
  }

  GetClients() {
    localStorage.setItem("ViaAgent", JSON.stringify(false));
    this.router.navigate(['/party']);
  }

  GetOrderPending() {
    this.router.navigate(['/pendingoffers']);
  }

  GetGradingStock() {
    this.router.navigate(['/gradingstock']);

  }

  GetGrayStock() {
    this.router.navigate(['/graystock']);
  }

  GetYarnStock() {
    this.router.navigate(['/factorystock']);
  }

  GetBill() {
    this.router.navigate(['/bill']);

  }
  GetGreyStock() {
    this.router.navigate(['/factorygreystock']);
  }

  showExitConfirm() {
    this.alertController.create({
      header: 'App Confirmation',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }



  // ionViewWillEnter()
  // {
  //   this.UserObj = JSON.parse(localStorage.getItem("UserObj"));
  //   console.log('ionViewWillEnter', this.UserObj);
  //   this.HomePageData = JSON.parse(localStorage.getItem("HomePageData"));
  //   console.log('ionViewWillEnter home..', this.HomePageData);
  // }




  ngOnInit() {
    this.UserObj = JSON.parse(localStorage.getItem("UserObj"));
    this.HomePageData = JSON.parse(localStorage.getItem("HomePageData"));
  }


  menuClosed() {
    console.log('close...');
  }

  menuOpened() {
    //code to execute when menu ha opened
    console.log('Open...');
  }

  openFirst() {
    this.menuCtrl.enable(true, 'first');
    this.menuCtrl.open('first');
    console.log('sss');
  }

  async Logout() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm?',
      message: 'Are sure to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Logout',
          handler: () => {
            localStorage.removeItem("CompanyInfo");
            localStorage.removeItem("UserObj");
            localStorage.clear();
            this.router.navigate(['/login'], { replaceUrl: true });
          }
        }
      ]
    });

    await alert.present();
  }


  ionDidOpen() {
    console.log('opp....');
  }

  ionDidClose() {
    console.log('closss..');
  }


  goToMenu1(val) {
    console.log(val);
  }

  openMenu(pages) {
    console.log(pages.subPages);
  }

  expandMenu(title) {
  }

  ionWillOpen() {
    console.log('44');
  }

  ionWillClose() {
    console.log('66');
  }

  GetHome() {
    this.router.navigate(['/home'], { replaceUrl: true });
  }
}
